import React from "react"
import { Link } from "gatsby"
import { Layout, SEO } from "../components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>PAGE NOT FOUND</h1>
    <Link to="/">返回首頁</Link>
  </Layout>
)

export default NotFoundPage
